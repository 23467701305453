<!-- =========================================================================================
File Name: RegisterJWT.vue
Description: Register Page for JWT
----------------------------------------------------------------------------------------
Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="clearfix">
    <vs-alert
      :active.sync="showDismissibleAlert"
      closable
      icon-pack="feather"
      close-icon="icon-x"
    >
      {{ flashMessage }}
    </vs-alert>
    <br />
    <vs-input
      v-validate="'required|min:3'"
      data-vv-validate-on="blur"
      data-vv-as="diplay name"
      label-placeholder="Name"
      name="displayName"
      placeholder="Name"
      v-model="displayName"
      class="w-full"
    />
    <span class="text-danger text-sm">{{ errors.first("displayName") }}</span>

    <vs-input
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="company name"
      name="companyName"
      label-placeholder="Company Name"
      placeholder="Company Name"
      v-model="companyName"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("companyName") }}</span>

    <vs-input
      v-validate=""
      data-vv-validate-on="blur"
      data-vv-as="franchise business name"
      name="franchiseBusiness"
      label-placeholder="Franchise Business Name"
      placeholder="Franchise Business Name(Optional)"
      v-model="franchiseBusinessName"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{
      errors.first("franchiseBusiness")
    }}</span>

    <vs-input
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="company ABN"
      name="abn"
      label-placeholder="Company ABN"
      placeholder="Company ABN"
      v-model="abn"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("abn") }}</span>

    <vs-input
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="head office street address"
      name="displayAddress"
      label-placeholder="Head Office Street Address"
      placeholder="Head Office Street Address"
      v-model="address.displayAddress"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{
      errors.first("displayAddress")
    }}</span>

    <vs-input
      v-validate="'required|email'"
      data-vv-validate-on="blur"
      name="email"
      type="email"
      label-placeholder="Email"
      placeholder="Email"
      v-model="email"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("email") }}</span>

    <vs-input
      ref="password"
      type="password"
      data-vv-validate-on="blur"
      v-validate="'required|min:8'"
      name="password"
      label-placeholder="Password"
      placeholder="Password"
      v-model="password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("password") }}</span>

    <vs-input
      type="password"
      v-validate="'confirmed:password'"
      data-vv-validate-on="blur"
      data-vv-as="password"
      name="confirm_password"
      label-placeholder="Confirm Password"
      placeholder="Confirm Password"
      v-model="confirm_password"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{
      errors.first("confirm_password")
    }}</span>

    <vs-input
      v-validate="'required'"
      data-vv-validate-on="blur"
      data-vv-as="contact number"
      label-placeholder="Contact Number"
      name="contactNumber"
      placeholder="Contact Number"
      v-model="contactNumber"
      class="w-full mt-6"
    />
    <span class="text-danger text-sm">{{ errors.first("contactNumber") }}</span>

    <vs-checkbox v-model="isTermsConditionAccepted" class="mt-6"
      >I accept the terms & conditions.</vs-checkbox
    >
    <vs-button
      class="float-right mt-6"
      @click="registerUserJWt"
      :disabled="!validateForm"
      >Register</vs-button
    >
    <br />
  </div>
</template>

<script>
export default {
  props: ["registerType"],
  data() {
    return {
      flashMessage: "",
      showDismissibleAlert: false,
      companyName: "",
      displayName: "",
      address: { displayAddress: "" },
      abn: "",
      email: "",
      password: "",
      confirm_password: "",
      franchiseBusinessName: "",
      contactNumber: "",
      isTermsConditionAccepted: true
    };
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.displayName != "" &&
        this.companyName != "" &&
        this.email != "" &&
        this.password != "" &&
        this.confirm_password != "" &&
        this.isTermsConditionAccepted === true
      );
    }
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {
        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          title: "Login Attempt",
          text: "You are already logged in!",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });

        return false;
      }
      return true;
    },
    registerUserJWt() {
      // If form is not validated or user is already login return
      if (!this.validateForm || !this.checkLogin()) return;

      const payload = {
        userDetails: {
          displayName: this.displayName,
          companyName: this.companyName,
          franchiseBusinessName: this.franchiseBusinessName,
          address: this.address,
          abn: this.abn,
          email: this.email,
          password: this.password,
          confirmPassword: this.confirm_password,
          contactNumber: this.contactNumber,
          userType: this.registerType || "admin"
        },
        notify: this.$vs.notify
      };
      this.$store
        .dispatch("auth/registerUserJWT", payload)
        .then(res => {})
        .catch(err => {
          this.flashMessage = err.response.data.message;
          this.showDismissibleAlert = true;
          this.$vs.notify({
            title: "Register Attempt",
            text: err.response.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "warning"
          });
        });
    }
  },
  mounted() {}
};
</script>
